// assets
import { IconListCheck } from '@tabler/icons';

// constant
const icons = {
    IconListCheck
};

import SecurityService from "services/SecurityService";

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const others = {
    id: 'others',
    title: 'Logs',
    type: 'group',
    children: []
};

if (SecurityService.userIsGrantedTo('event_logs')) {
    others.children.push({
        id: 'logs',
        title: 'Event logs',
        type: 'item',
        url: '/logs',
        icon: icons.IconListCheck,
        breadcrumbs: false
    });    
}

if (SecurityService.userIsGrantedTo('transmission_logs')) {
    others.children.push({
        id: 'transmission_logs',
        title: 'Transmission logs',
        type: 'item',
        url: '/transmission_logs',
        icon: IconListCheck,
        breadcrumbs: false
    });    
}

export default others;
