let es = {
  translation: {
    'Home': 'Inicio',
    'Welcome': 'Bienvenido',
    'Logout': 'Cerrar sesión',
    'English': 'Inglés',
    'Spanish': 'Español',
    'Portuguese': 'Portugués',
    'Event logs': 'Bitácora de acciones',
    'Event Logs': 'Bitácora de acciones',
    'Transmission logs': 'Bitácora de transmisiones',
    'Transmission Logs': 'Bitácora de transmisiones',
    'Roles': 'Roles',
    'Assisted auth': 'Autenticación asistida',
    'Permissions': 'Permisos',
    'Record details': 'Detalles del registro',
	'Back': 'Regresar',
    'Accounts': 'Cuentas',
    'Logs': 'Bitácoras',

    'Create': 'Adicionar',
    'Update': 'Actualizar',
    'Edit': 'Modificar',
    'Delete': 'Eliminar',
    'Cancel': 'Cancelar',
    'Name': 'Nombre',
    'Name Spanish': 'Nombre Español',
    'Name English': 'Nombre Inglés',
    'Name Portuguese': 'Nombre Portugués',
    'Create record': 'Adicionar registro',
    'Update record': 'Modificar registro',
    'Are you sure to delete this record?': '¿Está seguro de eliminar este registro?',
    'This operation is restricted to your user role': 'Esta operación no está permitida para su rol de usuario',
    'Permissions are already set for this user role': 'Los permisos de este rol de usuario ya han sido configurados',
    'Permissions of Role': 'Permisos del rol',
    'Read': 'Acceder',
    'Write': 'Modificar',
    'Remove': 'Eliminar',
    'List': 'Listar',
    'Has already been taken': 'Ya ha sido utilizado',
    'Range overflow': 'Por encima de lo permitido',
    'Pattern mismatch': 'Patrón incorrecto',
    'Range underflow': 'Por debajo de lo permitido',
    'Too long': 'Demasiado largo',
    'Type mismatch': 'Tipo de dato no válido',
    'Required': 'Requerido',
    'The operation was successful': 'La operación fue satisfactoria',
    'The operation could not be completed. Please, try again': 'No se pudo completar la operación. Por favor, inténtelo nuevamente',
    'The operation could not be completed. Possibly the instance has related records': 'La operacion no pudo ser completada. Posiblemente la instancia tiene registros relacionados',

    'Admin users': 'Administradores',
    'Users': 'Usuarios',
    'Last visit': 'Última visita',
    'More than 100 days': 'Hace más de 100 dias',
    'days': 'dias',
    'Never': 'Nunca',

    'Change password': 'Cambiar contraseña',
    'Update profile': 'Actualizar perfil',
    'Photo': 'Foto',
    'Assisted authentication': 'Autenticación asistida',
    'New password': 'Contraseña nueva',
    'Confirm password': 'Confirmar contraseña',
    'Password and confirm password do not match': 'La nueva contraseña y la contraseña de confirmación no coindicen',
    'Current password is not valid': 'La contraseña actual no es correcta',
    'New password is not secure enough': 'La nueva contraseña no es segura',
    'New password can not be equal to current password': 'La nueva contraseña no puede ser igual a la contraseña actual',
    'Edit profile': 'Editar perfil',
    'Last name': 'Primer apellido',
    'Second last name': 'Segundo apellido',
    'Password should have at least 8 characters with a combination of letters, numbers and special characters. Sequences such as 123 or abc are considered insecure.': 'La contraseña debe tener al menos 8 caracteres con una combinación de letras, números y caracteres especiales. Secuencias como 123 o abc se consideran inseguras.',

    'Clic on image to change profile photo': 'Clic en la imagen para cambiar la foto de perfil',

    'User profile': 'Perfil de usuario',
    'Username': 'Nombre de usuario',
    'Exit': 'Salir',

    'Others': 'Otros',
    'System status': 'Estado del sistema',
    
    'Opportunity': 'Oportunidad',
    'Project Value': 'Valor proyecto',
    'Potential Value': 'Valor potencial',
    'Consultant': 'Consultor',
    'Attack path': 'Ruta de ataque',
    "Date 1st strategy (act.)": "Fecha 1ª estrategia (act.)",
    'Active': 'Activo',
    'Users count': 'Cantidad de usuarios',
    'Winning probability': 'Probabilidad de ganar',

    "January": "Enero",
    "February": "Febrero",
    "March": "Marzo",
    "April": "Abril",
    "May": "Mayo",
    "June": "Junio",
    "July": "Julio",
    "August": "Agosto",
    "September": "Septiembre",
    "October": "Octubre",
    "November": "Noviembre",
    "December": "Diciembre",

    "Go Top": 'Ir Arriba',
    "Average of all winning chances.": "Promedio de las probabilidades de ganar de todas las oportunidades.",
    "The": "El",
    "the": "el",
    "is": "es",
    "Potential Value = Draft Value * Win Probability / 100": "Valor potencial = Valor proyecto * Probabilidad de ganar / 100",
    "Set all values to recommended": "Establecer todos los valores a los recomendados",
    "Set all column values to recommended": "Establecer en la columna todo los valores a los recomendados",
    "Sort rows by potential value": "Ordenar filas según su valor potencial."
  }
}

export default es;
